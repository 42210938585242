/* Base styles for larger screens */
body {
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.main {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
}
.main-content {
    width: 90%;
    height: 90%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr 5fr;
    column-gap: 7vw;
    grid-template-areas: 
        "main main"
        "profile box";
}
.main-title-box {
    grid-area: main;
    font-family: MaplestoryOTFBold;
    color: rgb(247, 244, 244);
    display: flex;
    justify-content: center;
    align-items: center;
}
.main-title-box > div {
    font-size: 50px;
    width: 400px;
    height: 50px;
}
.main-profile {
    background-color: rgba(255, 255, 255, 0.5);
    grid-area: profile;
}
.main-box {
    grid-area: box;
    gap: 10px;
}
.box {
    width: 100%;
    height: 45%;
    display: flex;
    flex-direction: row;
    gap: 5%;
}
.content {
    width: 30%;
    height: 100%;
}
.content.show {
}
.preview-image {
    width: 100%;
    height: 80%;
    background-size: 100% 80%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.preview-image.bringup {
    background-image: url('../../image/bringup.png');
}
.preview-image.geohub {
    background-image: url('../../image/geohub.png');
}
.content-info {
    font-family: MaplestoryOTFBold;
    font-size: 30px;
    color: whitesmoke;
    text-align: center;
    position: relative;
}
.content-title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.content-explanation {
    display: flex;
    flex-direction: column;
    justify-self: flex-end;
    font-size: 17px;
    align-self: center;
    text-align: center;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .main {
        align-items: flex-start;
        padding: 20px 0;
    }

    .main-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        gap: 20px;
        margin: 10px;
    }

    .main-title-box > div {
        font-size: 35px;
        width: 100%;
        text-align: center;
    }

    .main-profile,
    .box {
        width: 100%;
        height: auto;
    }

    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .content {
        width: 100%;
        height: auto;
    }

    .preview-image {
        height: 200px; /* Adjust height for mobile */
    }

    .content-info {
        font-size: 20px; /* Adjust font size for mobile */
    }

    .content-title {
        font-size: 25px; /* Adjust title font size for mobile */
    }

    .content-explanation {
        font-size: 14px; /* Adjust explanation font size for mobile */
    }
}
