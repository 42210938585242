.snowfall-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* 눈송이가 다른 요소와 상호작용하지 않도록 설정 */
  }
  
  .snowflake {
    position: absolute;
    top: -10px;
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 50%;
    opacity: 0.8;
    pointer-events: none;
    animation: fall linear infinite, drift ease-in-out infinite;
  }
  
  @keyframes fall {
    to {
      transform: translateY(100vh);
      opacity: 0;
    }
  }
  
  @keyframes drift {
    50% {
      transform: translateX(50px);
    }
  }